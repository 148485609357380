@import url('https://rsms.me/inter/inter.css');
html { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100vh;
}

h2 {
  font-size: 20px;
  color: #14171A;
  font-weight: 800;
}

h4 {
  font-size: 20px;
  color: #657786;
  font-weight: 300;
}

a {
  outline-style: none;
  border: 0px;
}

.btnPrimary {
  font-size: 18px;
  border-radius: 30px;
  color: #fff;
  padding: 16px 28px;
  font-weight: 700;
  border: 0px;
  background: linear-gradient(140deg, rgba(255,0,0,1) 0%, rgba(255,0,154,1) 100%);
  outline-style: none;
  cursor: pointer;
  transition: all .4s ease-out;
  display: inline-block;
  text-decoration: none;
}

.btnPrimary:hover {
  box-shadow: 0px 2px 10px rgba(255,0,154,0.25);
  transform: scale(1.01);
}

.btnPrimary:active {
  box-shadow: 0px 2px 10px rgba(255,0,154,0);
  transform: scale(0.98);
}
