.voicemsgBodyContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  position: relative;
}

.voicemsgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.1);
  border-radius: 24px;
  width: 300px;
  margin: auto;
}

.header {
  padding: 30px;
}

.footer {
  padding: 20px;
}
