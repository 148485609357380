.homeBodyContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  position: relative;
}

.homeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex-direction: column;
}

.homeBodyContainer .header {
  padding: 30px;
}

.homeBodyContainer .footer {
  padding: 20px;
}

.tagline {
  font-size: min(calc(100% + 5vw), 70px);
  font-weight: 800;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}
.homeBodyContainer h4 {
  text-align: center;
  font-size: min(calc(100% + 1vw), 24px);
}

.howItWorks {
  width: min(min(calc(100% + 1vw), 80%), 600px);
  margin: 40px;
  margin-bottom: 64px;
}
