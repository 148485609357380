@import url(https://rsms.me/inter/inter.css);
html { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100vh;
}

h2 {
  font-size: 20px;
  color: #14171A;
  font-weight: 800;
}

h4 {
  font-size: 20px;
  color: #657786;
  font-weight: 300;
}

a {
  outline-style: none;
  border: 0px;
}

.btnPrimary {
  font-size: 18px;
  border-radius: 30px;
  color: #fff;
  padding: 16px 28px;
  font-weight: 700;
  border: 0px;
  background: linear-gradient(140deg, rgba(255,0,0,1) 0%, rgba(255,0,154,1) 100%);
  outline-style: none;
  cursor: pointer;
  transition: all .4s ease-out;
  display: inline-block;
  text-decoration: none;
}

.btnPrimary:hover {
  box-shadow: 0px 2px 10px rgba(255,0,154,0.25);
  transform: scale(1.01);
}

.btnPrimary:active {
  box-shadow: 0px 2px 10px rgba(255,0,154,0);
  transform: scale(0.98);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.voicemsgBodyContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  position: relative;
}

.voicemsgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.1);
  border-radius: 24px;
  width: 300px;
  margin: auto;
}

.header {
  padding: 30px;
}

.footer {
  padding: 20px;
}


[data-custom-class='body'],
[data-custom-class='body'] * {
    background: transparent !important;
}

[data-custom-class='title'],
[data-custom-class='title'] * {
    font-family: Arial !important;
    font-size: 26px !important;
    color: #000000 !important;
}

[data-custom-class='subtitle'],
[data-custom-class='subtitle'] * {
    font-family: Arial !important;
    color: #595959 !important;
    font-size: 14px !important;
}

[data-custom-class='heading_1'],
[data-custom-class='heading_1'] * {
    font-family: Arial !important;
    font-size: 19px !important;
    color: #000000 !important;
}

[data-custom-class='heading_2'],
[data-custom-class='heading_2'] * {
    font-family: Arial !important;
    font-size: 17px !important;
    color: #000000 !important;
}

[data-custom-class='body_text'],
[data-custom-class='body_text'] * {
    color: #595959 !important;
    font-size: 14px !important;
    font-family: Arial !important;
}

[data-custom-class='link'],
[data-custom-class='link'] * {
    color: #3030F1 !important;
    font-size: 14px !important;
    font-family: Arial !important;
    word-break: break-word !important;
}

ul {
    list-style-type: square;
}

ul > li > ul {
    list-style-type: circle;
}

ul > li > ul > li > ul {
    list-style-type: square;
}

ol li {
    font-family: Arial;
}

.homeBodyContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  position: relative;
}

.homeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex-direction: column;
}

.homeBodyContainer .header {
  padding: 30px;
}

.homeBodyContainer .footer {
  padding: 20px;
}

.tagline {
  font-size: min(calc(100% + 5vw), 70px);
  font-weight: 800;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}
.homeBodyContainer h4 {
  text-align: center;
  font-size: min(calc(100% + 1vw), 24px);
}

.howItWorks {
  width: min(min(calc(100% + 1vw), 80%), 600px);
  margin: 40px;
  margin-bottom: 64px;
}

